import React from 'react';

import {
  BooleanInput,
  DateInput,
  EditBase,
  Form,
  maxValue,
  NumberInput,
  SaveButton,
  SelectInput,
  ShowButton,
  TextInput,
} from 'react-admin';
import { useParams } from 'react-router';

import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import * as resources from '@/api/resources';
import { PROMO_CODE_STATUSES } from '@/constants/promoCode';

const FormControls = () => (
  <Box display="flex" justifyContent="space-between">
    <ShowButton label="Cancel" icon={null} />
    <SaveButton label="Update" icon={null} />
  </Box>
);

const DetailsForm = () => {
  const formatValue = (value) => (value / 100).toFixed(2);
  const parseValue = (value) => Math.round(parseFloat(value) * 100);

  return (
    <>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextInput source="description" fullWidth />
          </Grid>
          <Grid item xs={4}>
            <DateInput source="start_date" fullWidth />
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              label="Max Amount"
              source="max_amount_cents"
              format={formatValue}
              parse={parseValue}
              validate={[maxValue(0)]}
            />
          </Grid>
          <Grid item xs={4}>
            <SelectInput source="promo_id_status" label="Status" choices={PROMO_CODE_STATUSES} fullWidth />
          </Grid>
          <Grid item xs={4}>
            <DateInput source="expiration_date" fullWidth />
          </Grid>

          <Grid item xs={4}>
            <NumberInput source="maximum_uses" fullWidth />
          </Grid>
          <Grid item xs={4}>
            <BooleanInput source="unlimited" fullWidth />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <FormControls />
      </CardContent>
    </>
  );
};

const PromoCodeEdit = () => {
  const { id: promoCodeId } = useParams();

  const transform = (data) => {
    if (data.max_amount_cents && data.max_amount_cents === 0) {
      return {
        ...data,
        max_amount_cents: null,
      };
    }
    return data;
  };

  return (
    <EditBase
      resource={resources.PROMO_CODES}
      id={promoCodeId}
      mutationMode="pessimistic"
      redirect={`/${resources.PROMO_CODES}/${promoCodeId}/show`}
      actions={false}
      transform={transform}
    >
      <Card mt={3} component={Box}>
        <CardHeader title="Overview" />
        <Divider />
        <Form>
          <DetailsForm />
        </Form>
      </Card>
    </EditBase>
  );
};

export default PromoCodeEdit;

import React from 'react';

import { BooleanField, DateField, Labeled, TextField } from 'react-admin';

import { Box, Card, CardContent, Divider, Grid } from '@mui/material';

import CurrencyField from '@/fields/CurrencyField';
import PromoCodeAmountField from '@/fields/PromoCodeAmountField';

import PromoCodeDetailsSectionHeader from '../Common/SectionHeader';

const OverviewSection = () => (
  <Card component={Box}>
    <PromoCodeDetailsSectionHeader title="Overview" />
    <Divider />
    <CardContent>
      <Grid container spacing={2} px={2}>
        <Grid item xs={3}>
          <Labeled>
            <TextField source="id" label="Name" />
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled>
            <TextField source="description" emptyText="N/A" />
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled>
            <TextField source="promo_id_type" label="Type" />
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled>
            <PromoCodeAmountField label="Amount" amountSource="promo_id_amount" percentSource="promo_id_percent" />
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled>
            <TextField source="promo_id_usage" label="Usage" />
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled>
            <TextField source="maximum_uses" />
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled>
            <BooleanField source="unlimited" emptyText="N/A" />
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled>
            <CurrencyField source="max_amount_cents" label="Max Amount" textAlign="left" emptyText="N/A" />
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled>
            <TextField source="promo_id_status" label="Status" />
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled>
            <DateField source="created_at" />
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled>
            <DateField source="start_date" />
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled>
            <DateField source="expiration_date" />
          </Labeled>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default OverviewSection;

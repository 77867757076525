export const MARKET_TAB = {
  DETAILS: 'details',
  PERFORMANCE_THRESHOLDS: 'performance-thresholds',
  TRAVEL_PAY: 'travel-pay',
  SERVICE_AREA: 'service-area',
  BASE_PAY: 'base-pay',
  MARKET_PRODUCT: 'market-product',
};

export const MARKET_DETAIL_SECTION = {
  PRIMARY_INFORMATION: 'primary-information',
};

export const MARKET_PERFORMANCE_THRESHOLDS_SECTION = {
  THRESHOLDS: 'thresholds',
};

export const MARKET_TRAVEL_PAY_SECTION = {
  OVERVIEW: 'overview',
};

export const MARKET_SERVICE_AREA_SECTION = {
  OVERVIEW: 'overview',
};

export const MARKET_SERVICE_BASE_PAY = {
  OVERVIEW: 'overview',
};

export const MARKET_STATUS = {
  ACTIVE: {
    id: 'Active',
    name: 'Active',
  },
  INACTIVE: {
    id: 'Inactive',
    name: 'Inactive',
  },
};

export const MARKET_STATUSES = [MARKET_STATUS.ACTIVE, MARKET_STATUS.INACTIVE];

export const MARKET_TYPES = [
  { id: 'full service', name: 'Full service' },
  { id: 'labor_only', name: 'Labor only' },
  { id: 'marketplace', name: 'Marketplace' },
];

export const MARKET_REGION = [
  { id: 'Southeast', name: 'Southeast' },
  { id: 'West', name: 'West' },
  { id: 'East', name: 'East' },
  { id: 'Midwest', name: 'Midwest' },
];

export const MARKET_PAYOUT_PROVIDERS = [
  { id: 'payreel', name: 'W2 (Payreel)' },
  { id: 'stripe', name: '1099 (Stripe - BHH Services)' },
  { id: 'split', name: 'Split' },
];

export const MARKET_BASE_PAY_QAULIFICATION_LEVELS = [
  { id: '0', name: 'Recruit' },
  { id: '1', name: 'Standard' },
  { id: '2', name: 'Lead' },
  { id: '3', name: 'Captain' },
];

export const MARKET_BASE_PAY_SERVICES = [
  { id: 'HOURLYLABOR', name: 'HOURLYLABOR' },
  { id: 'LOADING', name: 'LOADING' },
  { id: 'LOADINGUNLOADING', name: 'LOADINGUNLOADING' },
  { id: 'PACKINGMATERIALS', name: 'PACKINGMATERIALS' },
  { id: 'PACKINGSERVICE', name: 'PACKINGSERVICE' },
  { id: 'TRANSIT', name: 'TRANSIT' },
  { id: 'LDTRANSIT', name: 'LDTRANSIT' },
  { id: 'UNLOADING', name: 'UNLOADING' },
  { id: 'LDLOADING', name: 'LDLOADING' },
  { id: 'LDUNLOADING', name: 'LDUNLOADING' },
];

export const MARKET_BELLHOP_NATION = 'BELLHOPNATION';

export const MARKET_ACTION = {
  CREATE_POSTAL_CODE: 'create_postal_code',
  CREATE_MANY_POSTAL_CODE: 'create_many_postal_code',
  UPDATE_POSTAL_CODE: 'update_postal_code',
  CREATE_MARKET_PRODUCT: 'create_market_product',
  DELETE_MARKET_PRODUCT: 'delete_market_product',
  UPDATE_MARKET_PRODUCT: 'update_market_product',
};

export const MARKET_PRODUCT_SERVICES = [
  ...MARKET_BASE_PAY_SERVICES,
  { id: 'FINALMILE', name: 'FINALMILE' },
  { id: 'JUNKREMOVAL', name: 'JUNKREMOVAL' },
  { id: 'CARSHIPPING', name: 'CARSHIPPING' },
  { id: 'RELOSERVICES', name: 'RELOSERVICES' },
];

export const MARKET_SERVICEABILITY_PARTNERS = {
  HOME_DEPOT: {
    id: 'HOME_DEPOT',
    name: 'Home Depot',
  },
  OFFERPAD: {
    id: 'OFFERPAD',
    name: 'Offerpad',
  },
  PODS: {
    id: 'PODS',
    name: 'PODS',
  },
  ZIPPY: {
    id: 'ZIPPY',
    name: 'Zippy Shell',
  },
};

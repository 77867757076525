import React from 'react';

import dayjs from 'dayjs';
import {
  ChipField,
  Datagrid,
  DeleteButton,
  RecordContextProvider,
  ReferenceField,
  usePermissions,
  useRecordContext,
  useRefresh,
  useShowContext,
} from 'react-admin';
import mx from 'mixpanel-browser';

import { Box, Card, Chip, Divider, Typography } from '@mui/material';

import List from '@/ReactAdmin/List';
import * as resources from '@/api/resources';
import CurrencyField from '@/fields/CurrencyField';
import DayJsField from '@/fields/DayJsField';
import PrefDateTimeField from '@/fields/PrefDateTimeField';
import EmptyResourceList from '@/shared/EmptyResourceList';
import LinkButton from '@/shared/LinkButton';
import getServiceName from '@/utils/serviceName/getServiceName';

import QuoteInfoField from './QuoteInfoField';

const DeleteCustomerQuoteButton = () => {
  const { permissions } = usePermissions();
  const refresh = useRefresh();
  const quoteRecord = useRecordContext();

  if (!permissions.tools?.hqadmin?.quotes?.delete) return null;

  return (
    <DeleteButton
      label="Delete"
      confirmTitle="Delete Quote"
      onClick={() =>
        mx.track('Quote Deleted', {
          id: quoteRecord?.id,
          customer: quoteRecord?.customer_id,
          affiliate: quoteRecord?.affiliate_id,
        })
      }
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          refresh();
        },
      }}
    />
  );
};

const QuoteServicesField = () => {
  const quoteRecord = useRecordContext();

  const services = quoteRecord?.service_groups?.map((serviceGroup) => serviceGroup.services).flat();

  if (!quoteRecord || !services?.length) return null;

  return (
    <Box display="flex" gap={1}>
      {services?.map((service) => (
        <Chip size="small" key={service.id} label={getServiceName(service.service_id)} />
      ))}
    </Box>
  );
};

const EarliestServiceDateField = () => {
  const quoteRecord = useRecordContext();

  const services = quoteRecord?.service_groups?.map((serviceGroup) => serviceGroup.services).flat();

  const initialService = { start_datetime: dayjs().year(2099) };
  const earliestService = services.reduce(
    (prevService, service) => (dayjs(service.start_datetime) < dayjs(prevService.start_datetime) ? service : prevService),
    initialService,
  );

  return (
    <RecordContextProvider value={earliestService}>
      <PrefDateTimeField source="start_datetime" timezoneSource="start_timezone" dayjsFormat="M/D/YYYY @ h A" />
    </RecordContextProvider>
  );
};

const CustomerContentQuotes = () => {
  // get context of the current record being used
  const { record: customerRecord } = useShowContext();
  const { permissions } = usePermissions();

  if (!customerRecord) return null;

  const canCreateQuote = permissions?.tools?.hqadmin?.quotes?.create;
  const canUpdateQuote = permissions?.tools?.hqadmin?.quotes?.update;

  const rowClickProp = {
    ...(canUpdateQuote ? { rowClick: 'edit' } : {}),
  };

  return (
    <Card mt={3}>
      <Box px={3} py={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Quotes</Typography>
        {canCreateQuote ? (
          <LinkButton
            label="Create Quote"
            to={`/${resources.QUOTES}/create`}
            state={{ record: { customer_id: customerRecord?.id } }}
          />
        ) : null}
      </Box>
      <Divider />
      <List
        disableSyncWithLocation
        resource={resources.QUOTES}
        filter={{ customer_id: customerRecord?.id }}
        exporter={false}
        empty={<EmptyResourceList text="No quotes on this customer account" />}
        storeKey={`${resources.CUSTOMERS}.${resources.QUOTES}.listParams`}
        actions={null}
        sort={{ field: 'updated_at', order: 'DESC' }}
      >
        <Datagrid bulkActionButtons={false} {...rowClickProp}>
          <QuoteInfoField label="" />
          <DayJsField source="created_at" label="Created" format={`M/D/YYYY\nh:mm A`} />
          <DayJsField source="updated_at" label="Last Update" format={`M/D/YYYY\nh:mm A`} />
          <QuoteServicesField label="Services" sortable={false} />
          <EarliestServiceDateField label="Start Date" sortable={false} />
          <CurrencyField source="totals.total" label="Total" textAlign="left" sortable={false} />
          <ReferenceField
            source="partner_id"
            label="Partner"
            reference={resources.ACCOUNTS}
            key="partner_id"
            sortable={false}
          >
            <ChipField size="small" source="name" />
          </ReferenceField>
          <DeleteCustomerQuoteButton />
        </Datagrid>
      </List>
    </Card>
  );
};

export default CustomerContentQuotes;

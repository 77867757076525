import React from 'react';
import { bool, number, string } from 'prop-types';
import { useRecordContext } from 'react-admin';
import { get } from 'lodash';
import { Box } from '@mui/material';
import TextFieldWrapper from '@/fields/TextFieldWrapper';
import formatCurrency from '@/utils/currency/formatCurrency';

const CurrencyField = ({ source, textAlign, fractionDigits, isFraction, emptyText, suffix }) => {
  const record = useRecordContext();
  const value = get(record, source);

  if (!record || typeof value === 'undefined' || value === null) {
    return <TextFieldWrapper value={emptyText} />;
  }

  return (
    <Box sx={{ textAlign }}>
      <TextFieldWrapper value={`${formatCurrency(isFraction ? value * 100 : value, fractionDigits)}${suffix}`} />
    </Box>
  );
};

CurrencyField.propTypes = {
  source: string.isRequired,
  textAlign: string,
  fractionDigits: number,
  isFraction: bool,
  emptyText: string,
  suffix: string,
};

CurrencyField.defaultProps = {
  textAlign: 'right',
  fractionDigits: 2,
  isFraction: false,
  emptyText: '',
  suffix: '',
};

export default CurrencyField;

import React, { useContext, useEffect, useState } from 'react';

import mx from 'mixpanel-browser';
import Papa from 'papaparse';
import { bool } from 'prop-types';
import {
  ArrayInput,
  FileField,
  FileInput,
  Form,
  SimpleFormIterator,
  useCreate,
  useNotify,
  useRefresh,
  useShowContext,
} from 'react-admin';

import { Alert, Box, Button, CircularProgress, Divider, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { MarketActionContext } from '@/providers/MarketAction';
import { useFormContext, useWatch } from 'react-hook-form';

const CreatePostalCodeForm = ({ isCreating }) => {
  const { setValue, clearErrors } = useFormContext();
  const { setAction } = useContext(MarketActionContext);
  const postalCodesCsvFile = useWatch({ name: 'postal_codes_raw' });
  const parsedPostalCodes = useWatch({ name: 'parsed_postal_codes' });

  const [parsedErrors, setParsedErrors] = useState([]);
  const [isParsing, setIsParsing] = useState(false);

  const validateRow = ({ postal_code: postalCode }) => {
    if (!postalCode) {
      return {
        result: false,
        error: 'missing values',
      };
    }
    return {
      result: true,
    };
  };

  const parseResults = (results) => {
    const parsed = results.data
      .map((result) => {
        const { postal_code: postalCode } = result;
        const valid = validateRow(result);
        return {
          postal_code: postalCode,
          valid,
        };
      })
      .filter((item) => !!item);
    const errors = parsed.filter((item) => item.valid?.result === false);

    setParsedErrors(errors);
    setValue('parsed_postal_codes', parsed);
    setIsParsing(false);
  };

  const downloadCsvTemplate = () => {
    const csvDataMap = [{ postal_code: '<postal_code>' }];
    const headers = ['postal_code'].join(',');
    const csvContent = `data:text/csv;charset=utf-8,${headers}\r\n${csvDataMap
      .map((e) => Object.values(e).join(','))
      .join(`\r\n`)}`;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'market_postal_codes_template.csv');
    link.click();
  };

  useEffect(() => {
    setParsedErrors([]);
    clearErrors();
    if (postalCodesCsvFile?.rawFile) {
      setIsParsing(true);
      Papa.parse(postalCodesCsvFile.rawFile, {
        complete: parseResults,
        skipEmptyLines: true,
        header: true,
      });
    }
  }, [postalCodesCsvFile]);

  return (
    <>
      {/* Hidden input holder for layers parsed from CSV */}
      <ArrayInput source="parsed_postal_codes" label="">
        <SimpleFormIterator inline sx={{ display: 'none' }} />
      </ArrayInput>
      <Box textAlign="center" pt={2}>
        <Button variant="outlined" onClick={downloadCsvTemplate}>
          Download Empty Template
        </Button>
      </Box>
      <Box px={2} pt={2}>
        <FileInput label={false} source="postal_codes_raw" accept="text/csv" multiple={false}>
          <FileField source="src" title="title" />
        </FileInput>
        {isParsing ? <CircularProgress size={40} /> : null}
        <Box display="flex" flexDirection="column" gap={2} mb={2} mt={2}>
          {parsedErrors?.length ? (
            <Alert variant="filled" severity="error">{`${parsedErrors.length} error${
              parsedErrors.length !== 1 ? 's' : ''
            } found`}</Alert>
          ) : null}
          {parsedPostalCodes ? (
            <Alert variant="filled" severity="info">{`${parsedPostalCodes.length} postal code${
              parsedPostalCodes.length !== 1 ? 's' : ''
            } parsed from CSV`}</Alert>
          ) : null}
        </Box>
      </Box>
      <Divider />
      <Box pt={2} px={2} display="flex" justifyContent="space-between">
        <Button variant="contained" color="neutral" onClick={() => setAction(null)}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          disabled={isCreating ?? null}
          startIcon={isCreating ? <CircularProgress size={18} /> : null}
        >
          Submit
        </Button>
      </Box>
    </>
  );
};

CreatePostalCodeForm.propTypes = {
  isCreating: bool.isRequired,
};

const CreateManyPostalCode = () => {
  const { setAction } = useContext(MarketActionContext);
  const { record: { id: marketId } = {} } = useShowContext();
  const [create, { isLoading: isCreating }] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();

  const onError = (error) => {
    let { message } = error;
    if (error.body?.detail) {
      message = error.body.detail;
    }

    notify(`Error - adding Postal Codes failed - ${error.status} - ${message}`, {
      type: 'error',
    });
    mx.track(`Market Management - Error adding Postal Codes`, {
      resource: resources.MARKETS,
      resourceId: marketId,
      error,
    });
  };

  const onSuccess = () => {
    mx.track(`Market Management - Postal Code - CSV Uploaded`, {
      resource: resources.MARKETS,
      resourceId: marketId,
    });

    notify(`Postal Codes added`, {
      type: 'success',
    });
    refresh();
    setAction(null);
  };

  const handleSubmit = (data) => {
    create(
      resources.MARKETS,
      {
        data: {
          postal_codes: data.parsed_postal_codes,
        },
        meta: {
          resourceId: marketId,
          subResource: 'postal_codes',
        },
      },
      { mutationMode: 'pessimistic', onError, onSuccess },
    );
  };

  return (
    <Box px={2} py={4} width={475}>
      <Typography variant="h4" component={Box} pb={4} pl={2}>
        Add Postal Code
      </Typography>
      <Divider />
      <Box>
        <Form onSubmit={handleSubmit}>
          <CreatePostalCodeForm isCreating={isCreating} />
        </Form>
      </Box>
    </Box>
  );
};

export default CreateManyPostalCode;
